import React from "react";
import { StaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components";
import Img from "gatsby-image";
import LinkButton from "./LinkButton";
import CallButton from "./CallButton";
import OverenaFirma from "./OverenaFirma";
import { useMediaQuery } from 'react-responsive';
import LinkButtonV2 from "../brandingV2/LinkButtonV2";



const ProcesBezObraduInner = styled.div`
  margin: 70px auto;
  margin-top: 100px;

  @media (max-width: 899px) {
    margin: 50px auto;
    margin-top: 90px;
    width: calc(100% - 50px);
    max-width: 600px;
  }

  h2 {
    font-family: NewKansas;
    font-size: 32px;
    color: #2c4668;
    text-align: center;
    margin-bottom: 70px;

    @media (max-width: 899px) {
      line-height: 35px;
      text-align: center;
      font-size: 25px;
    }
  }

  .step {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1000px;
    margin: 30px auto;
    width: calc(100% - 100px);
    position: relative;
    left: -30px;

    @media (max-width: 899px) {
      display: block;
      width: calc(100%);
      margin: 0 auto;
      position: static;
      max-width: 600px;
    }

    .left {
      @media (max-width: 899px) {
        margin-bottom: 35px;
        margin-top: 60px;
      }
    }

    .right {
      width: calc(100% - 350px);
      max-width: 600px;

      @media (max-width: 899px) {
        width: calc(100%);
      }
      
      h3 {
        font-size: 26px;
        color: #303c4b;
        line-height: 40px;
        margin-bottom: 25px;
        font-family: Visuelt-Medium;

        @media (max-width: 899px) {
          font-size: 23px;
          text-align: left;
          line-height: 35px;
          margin-bottom: 20px;
        }
      }

      p {
        font-family: Visuelt-Regular;
        line-height: 28px;
        font-size: 18px;
        color: #394B5C;

        a {
          color: #394B5C;
        }
      }
    }
  }

  .stepOtoceny {
    left: 30px;

    .left {
      order: 2;
    }

    .right {
      order: 1;
    }
  }

  .linkButton {
    margin: 0 auto;
    display: block;
    margin-top: 30px;
    width: fit-content;

    @media (max-width: 899px) {
      margin-left: 0;
      margin-top: 60px;
    }
  }
`;



const ProcesBezObradu = () => {
  const isLess1000 = useMediaQuery({
    query: '(max-width: 1000px)'
  });

  const isLess899 = useMediaQuery({
    query: '(max-width: 899px)'
  });


  return (
    <StaticQuery
      query={graphql`
        query {
          obrazek1: file(relativePath: { eq: "bezobraduObrazek1.png" }) {
            childImageSharp {
              fixed(width: 286, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }

          obrazek2: file(relativePath: { eq: "bezobraduObrazek2.png" }) {
            childImageSharp {
              fixed(width: 299, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }

          obrazek3: file(relativePath: { eq: "bezobraduObrazek3.png" }) {
            childImageSharp {
              fixed(width: 286, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }

          obrazek1Small: file(relativePath: { eq: "bezobraduObrazek1.png" }) {
            childImageSharp {
              fixed(width: 201, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }

          obrazek2Small: file(relativePath: { eq: "bezobraduObrazek2.png" }) {
            childImageSharp {
              fixed(width: 207, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }

          obrazek3Small: file(relativePath: { eq: "bezobraduObrazek3.png" }) {
            childImageSharp {
              fixed(width: 201, quality: 100) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
        }
      `}
      render={data => (
        <ProcesBezObraduInner>
          <h2>Jak to celé funguje?</h2>
          <div className="step">
            <div className="left">
              <Img className="obrazek" alt="Záznická podpora" fixed={isLess899 ? data.obrazek1Small.childImageSharp.fixed : data.obrazek1.childImageSharp.fixed} />
            </div>
            <div className="right">
              <h3>Nezávazně si zavoláme a probereme vaši situaci</h3>
              <p>Zavolejte nám a jednoduše se zeptejte na vše, co vás zajímá. Na telefonu jsme pro vás každý den, ve dne v noci.</p>
            </div>
          </div>
          <div className="step stepOtoceny">
            <div className="left">
              <Img className="obrazek" alt="Pohřební vůz" fixed={isLess899 ? data.obrazek2Small.childImageSharp.fixed : data.obrazek2.childImageSharp.fixed} />
            </div>
            <div className="right">
              <h3>Odvezeme vašeho blízkého</h3>
              <p>Vašeho blízkého odvezeme odkudkoliv z vašeho regionu: z nemocnice, domova, nebo od jiné pohřební služby. Mezitím za vás vyřídíme všechny potřebné dokumenty.</p>
            </div>
          </div>
          <div className="step">
            <div className="left">
              <Img className="obrazek" alt="Doručení urny domů" fixed={isLess899 ? data.obrazek3Small.childImageSharp.fixed : data.obrazek3.childImageSharp.fixed} />
            </div>
            <div className="right">
              <h3>Předáme vám urnu s popelem</h3>
              {/* <h3>Osobně vám doručíme urnu</h3> */}
              <p>Popel zesnulého vám předáme zhruba do tří týdnů. Poslední rozloučení a jak ho chcete pojmout je pak už jen <Link to="/poradna/kremacni-popel/">na vás</Link>.</p>
            </div>
          </div>
          <LinkButtonV2 to="/nabidka/dotaznik/" text="Začít plánovat pohřeb" />
        </ProcesBezObraduInner>
      )}
    />
  );
}

export default ProcesBezObradu;

